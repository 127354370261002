header.twAppBar {
	@apply bg-white border-b-4 border-primary;
}

.twDesktop {
	@apply hidden md:flex;
}

.twMobile {
	@apply flex md:hidden;
}

button.twMobileMenuItem {
	@apply w-full justify-between;
}

.twSearch {
	@apply relative rounded-md w-full bg-white/10
			md:w-auto md:ml-2 hover:bg-white/25;
}

.twSearchIconWrapper {
	@apply absolute px-4 h-full pointer-events-none
			flex items-center justify-center;
}

.twInputSearch {
	@apply text-inherit w-full;
}

.twInputSearch input {
	@apply p-2 pl-12 transition-[width] w-full
			md:w-[160px] md:focus:w-[235px];
}

.twInputSearch input::-webkit-search-cancel-button {
	@apply inline-block w-[12px] h-[12px] cursor-pointer appearance-none;
	background: linear-gradient(
			45deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0) 43%,
			#fff 45%,
			#fff 55%,
			rgba(0, 0, 0, 0) 57%,
			rgba(0, 0, 0, 0) 100%
		),
		linear-gradient(
			135deg,
			transparent 0%,
			transparent 43%,
			#fff 45%,
			#fff 55%,
			transparent 57%,
			transparent 100%
		);
}

.twLogo {
	@apply w-20 h-12;
}

.twMenuAppbar {
	@apply bg-white;
}
