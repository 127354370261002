.twPortada {
	@apply contrast-150 bg-[url('src/assets/images/banner.jpg')] bg-cover bg-no-repeat bg-center;
}

.twBloque {
	@apply h-[500px];
}

h1.title {
	@apply -sm:text-3xl text-center md:px-40 pt-[7.5rem] pb-4 uppercase;
	text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5), 0px 0px 10px rgba(0, 0, 0, 0.5), 0px 0px 15px rgba(0, 0, 0, 0.5),
		0px 0px 20px rgba(0, 0, 0, 0.4), 0px 0px 30px rgba(0, 0, 0, 0.3), 0px 0px 40px rgba(0, 0, 0, 0.2),
		0px 0px 55px rgba(0, 0, 0, 0.1), 0px 0px 75px rgba(0, 0, 0, 0);
}

div.twTarjeta {
	@apply max-w-[300px] mx-auto shadow-lg hover:animate-wave;
}

.twTarjetaImage {
	@apply max-w-[300px] h-[180px] contrast-150;
}

.twWaves {
	@apply max-w-[80px] mx-auto;
}

.twButtonContainer {
	@apply flex justify-center my-3;
}

div.twGridCard {
	@apply max-w-[300px];
}
