.twMap1Container {
	@apply !flex -sm:flex-wrap gap-4;
}
.twMap1 {
	@apply w-full h-[300px] sm:h-[400px] md:h-[600px] rounded-lg shadow-2xl;
}
.twInfoWindow {
	@apply text-primary;
}
.twMap {
	@apply w-full sm:w-1/2;
}
.twVideo {
	@apply w-full sm:w-1/2;
}
.twPlayer {
	@apply overflow-hidden rounded-lg min-h-[300px];
}
.twPlace {
	color: #c70808;
}
