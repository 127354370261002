.twContactUs {
	@apply relative bg-white -sm:w-full shadow-lg pt-10 rounded-lg
		sm:min-w-[500px] overflow-hidden;
}

.twContactUs h2 {
	@apply -sm:text-4xl;
}

.twLoading {
	@apply absolute top-0 left-0 right-0 bottom-0 bg-black/30
		z-10 flex justify-center items-center;
}
