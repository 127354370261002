.twPerson {
	@apply cursor-pointer transition-all;
}

.twPersonSelected {
	@apply filter-none hover:filter-none animate-bounce;
}

.twBoxAvatar {
	@apply w-[120px] h-[120px] overflow-hidden rounded-full mx-auto;
}

.twAvatar {
	@apply w-full h-full bg-cover transition-all bg-center;
}

.twAvatarInitial {
	@apply brightness-50 hover:scale-110;
}
