@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Barlow Condensed';
	src: url('./assets/fonts/BarlowCondensed-Regular.ttf') format('truetype');
	font-weight: normal;
}

@font-face {
	font-family: 'Barlow Condensed';
	src: url('./assets/fonts/BarlowCondensed-SemiBold.ttf') format('truetype');
	font-weight: 600;
}

@font-face {
	font-family: 'Barlow Condensed';
	src: url('./assets/fonts/BarlowCondensed-Bold.ttf') format('truetype');
	font-weight: 700;
}

html {
	@apply font-bc;
}

html,
body,
#root {
	@apply h-full;
}

#root {
	@apply flex flex-col overflow-auto bg-primary;
}
