.twPortada {
	@apply brightness-75 contrast-150 bg-cover bg-no-repeat bg-center;
	background-image: url('src/assets/images/banner2.jpg');
}

.twBloque {
	@apply h-[272px] border-b-8 border-divider/[85];
}

.twWaves {
	@apply max-w-[120px] mx-auto;
}
